import React from "react";
import { Col, Row, Typography } from "antd";
import { FacebookFilled, FacebookOutlined, InstagramOutlined } from "@ant-design/icons";
import CookieConsent from "react-cookie-consent";
const { Text, Title, Paragraph } = Typography;

export const Footer = () => {
  return (
    <footer
      style={{
        marginTop: 50,
        padding: "20px 50px",
        backgroundColor: "#333",
        color: "#fff",
        textAlign: "center",
      }}
    >
      <Row justify={"center"}>
        <Col xs={24} sm={20} md={18} lg={14}>
          <Title level={3} style={{ color: "#fff" }}>
            BORA Dental Implants
          </Title>
          <Paragraph style={{ fontSize: 14, color: "#ccc" }}>
            © {new Date().getFullYear()} BORA. All rights reserved.
            {/* | Privacy Policy | Terms of Service */}
          </Paragraph>
          <Paragraph style={{ fontSize: 14, color: "#ccc" }}>
            info@medbora.de | +491783059595 
          </Paragraph>
          <Paragraph>
          <a
              href="https://www.facebook.com/profile.php?id=61565212289241"
              target="_blank"
              style={{ fontSize:18, color:"#415ca0", marginRight: 10 }}

            >
              <FacebookOutlined  />
            </a> 
            <a
              href="https://www.instagram.com/vitadentalimplant"
              target="_blank"
              style={{ color:"#b5378e", fontSize:18 }}
            >
              <InstagramOutlined/>
            </a>
            </Paragraph>
            <CookieConsent
              location="bottom"
              buttonText="Accept"
              declineButtonText="Decline"
              enableDeclineButton
              cookieName="refineCookies"
              style={{ background: "#2B373B", color: "#fff", width: "100%", justifyContent: "center", alignItems: "flex-end" }}
              buttonStyle={{
                  color: "#4e503b",
                  fontSize: "13px",
                  background: "#ffd700",
                  borderRadius: "5px",
              }}
              declineButtonStyle={{
                  background: "#f44336",
                  fontSize: "13px",
                  borderRadius: "5px",
                  
              }}
              expires={60} // Expiration in days
              onAccept={() => {
                  console.log("Cookies accepted!");
                  localStorage.setItem("cookiesAccepted", "true");
              }}
              onDecline={() => {
                  console.log("Cookies declined!");
                  localStorage.setItem("cookiesAccepted", "false");
              }}
            >
              <h2 style={{ fontWeight:"bolder", textAlign: "left" }}>This website uses cookies</h2>  
              <p style={{  textAlign: "left" }}>
              Our website uses cookies to enhance your browsing experience and improve site performance. We do not collect or store personal information without your explicit consent. The cookies we use include:
              </p>
              <p style={{  textAlign: "left" }}>Essential Cookies: Required for the site to function properly.</p>
              <p style={{  textAlign: "left" }}>Performance Cookies: Used to gather anonymous data about how visitors interact with the site, helping us improve usability.</p>
          </CookieConsent>
        </Col>
      </Row>
    </footer>
  );
};
