import React from "react";
import { Image, Row, Col, Typography, Card, Button } from "antd";
import "./style.css";
import {
  useTranslate,
  useCustom,
  useDelete,
  useGetIdentity,
} from "@refinedev/core";
import routerProvider from "@refinedev/react-router-v6/legacy";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";

const { Paragraph } = Typography;
type IUser = {
  id: number;
  name: string;
  avatar: string;
};
export const Home = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const history = routerProvider.useHistory();
  const { mutate: mutateDelete } = useDelete();
  const { data: user } = useGetIdentity<IUser>();

  const { data, refetch }: any = useCustom({
    url: `https://medbora.de/api/categories`,
    method: "get",
    config: {
      headers: {
        "Content-Type": "image/jpeg",
      },
    },
  });

  console.log("data", data?.data);

  return (
    <>
      <div style={{ margin: "0px", padding: "0px" }}>
        <div>
          <Image
            src="./images/bora/01.jpg"
            preview={false}
            width={"100%"}
            height={800}
          />
        </div>
        <div className="mask">
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            justify={"center"}
            style={{ margin: 0, padding: 0, alignItems: "center" }}
          >
            <Col className="gutter-row" span={14}>
              <div className="mask__content">
                <Paragraph className="title_mask">VITA</Paragraph>

                <Paragraph className="title_mask">
                  Only the best will do
                </Paragraph>
                <Paragraph className="title_mask">
                  {t("home.medicalCenter")}
                </Paragraph>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* Categories Section */}
      {user && (
        <Row
          gutter={16}
          justify={"center"}
          align={"top"}
          style={{ marginTop: 90 }}
        >
          <Col>
            <h3>Categories</h3>
          </Col>
          <Col>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => {
                history.push("/categories/create");
              }}
            >
              Create New Category
            </Button>
          </Col>
        </Row>
      )}
      <Row
        gutter={[24, 24]}
        justify={"space-around"}
        align={"top"}
        style={{ marginTop: 90, marginLeft: 25 }}
      >
        {data?.data.map((cate: any) => {
          return (
            <Col xs={24} sm={12} md={12} lg={12} xl={6} key={cate._id}>
              <Card
                className="card-container"
                hoverable
                style={{
                  width: "90%",
                  marginBottom: 30,
                  height: 350,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 12,
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                }}
                cover={
                  <Image
                    src={`https://medbora.de/uploads/${cate.image}`}
                    preview={false}
                    style={{ objectFit: "cover" }}
                    height={200}
                    className="card-image"
                    width={150}
                    onClick={() => {
                      dispatch({
                        type: "SELECTED_CATEGORY",
                        payload: cate,
                      });
                      history.push("/products/by-category");
                    }}
                  />
                }
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-30px)";
                  e.currentTarget.style.boxShadow =
                    "0 8px 20px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow =
                    "0 4px 12px rgba(0, 0, 0, 0.1)";
                }}
              >
                
                <h3 style={{ fontSize: 12, fontWeight: "bolder", marginTop: 20 }} >{cate.name} </h3>
                {user && (
                  <div style={{ marginTop: 16 }}>
                    <Button
                      size="small"
                      icon={<EditFilled style={{ color: "#4096ff" }} />}
                      onClick={() => {
                        dispatch({
                          type: "SELECTED_CATEGORY",
                          payload: cate,
                        });
                        history.push("/categories/edit");
                      }}
                      style={{ marginRight: 8 }}
                    />
                    <Button
                      size="small"
                      icon={<DeleteFilled style={{ color: "red" }} />}
                      onClick={() => {
                        mutateDelete(
                          {
                            resource: "categories",
                            id: cate._id,
                          },
                          {
                            onSuccess() {
                              refetch();
                            },
                          }
                        );
                      }}
                    />
                  </div>
                )}
              </Card>
            </Col>
          );
        })}
      </Row>
      {/* Goals Section */}
      <br /> <br /> <br />
      <Row justify={"center"}>
        <Col xs={24} md={12} style={{ marginLeft: 30 }}>
          <Row justify={"end"}>
            <Col xs={24} md={20}>
              <h1 className="aboutus__title">OUR GOAL</h1>
              <p className="aboutus__content">
                At BORA, we are dedicated to advancing dental implant technology
                to provide optimal solutions for both practitioners and
                patients. Based in Germany, a country renowned for its precision
                engineering and innovative healthcare solutions, BORA stands at
                the forefront of Dental Implantology.
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={11}>
          <Row justify={"end"}>
            <Col xs={24} md={20}>
              <Image
                src="./images/bora/08.jpg"
                preview={false}
                style={{
                  borderRadius: 15,
                  width: "100%",
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* About Us Section */}
      <br /> <br /> <br />
      <Row justify={"center"}>
        <Col xs={24} md={11}>
          <Row justify={"end"}>
            <Col xs={24} md={20}>
              <Image
                src="./images/bora/09.jpg"
                preview={false}
                style={{
                  borderRadius: 15,
                  width: "100%",
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={12} style={{ marginLeft: 30 }}>
          <h1 className="aboutus__title">ABOUT US</h1>
          <Row justify={"start"}>
            <Col xs={24} md={20}>
              <p className="aboutus__content">
                Our company is based in Germany , we are specialists in dental
                products. All our products are proudly MADE IN GERMANY using the
                latest dental technology. We value our partnerships with dental
                professionals around the world. Our goal is to support you with
                exceptional products that help you provide the best possible
                care for your patients. With BORA, you can trust that you’re
                using implants designed for excellence and reliability.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
       {/* ISO Section */}
       <br /> <br /> <br />
      <Row  gutter={[24, 24]} justify={"space-around"}  style={{ marginLeft: 50, marginRight: 20}}>
      {/* <h1 className="aboutus__title" >MANUFACTURER BY</h1> */}
       
        <Col>
         <Image
                src="./images/bora/fda.png"
                preview={false}
                style={{
                  width:  130,
                  height:60
                }}
              />
         </Col>
        
         <Col >
          <Row justify={"center"}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Image
              src="./images/bora/CE.png"
              preview={false}
              style={{
                borderRadius: 15,
                width: 85,
                height:60
              }}
            /> 
            </Col>
            <Col  xs={24} sm={24} md={24} lg={24} xl={24}>
                <h1 style={{fontSize: 30, fontWeight: "bolder", color: "#6b6b6b", marginLeft: 10}}>1023</h1>
            </Col>
          </Row>
         </Col>
         <Col >
            <Image
                src="./images/bora/iso.svg"
                preview={false}
                style={{
                  marginTop: -15,
                  width:  130,
                  height:130
                }}
              />
         </Col>
         <Col >
          <h3 style={{ fontWeight: "bolder", lineHeight: "2" }}>MANUFACTURER BY</h3>
          <p style={{ lineHeight: "0.3"}}>Alpha Dent Implants GmbH</p>
          <p style={{ lineHeight: "0.3"}}>Hanauer Straße 8</p>
          <p style={{ lineHeight: "0.3"}}>75181 Pforzheim</p>
          <p style={{ lineHeight: "0.3"}}>Germany</p>
          <Image
            src="./images/bora/germany.png"
            preview={false}
            style={{

              width: 100,
              height:60
            }}
          />
        </Col>
      </Row>
      {/* Footer */}
    </>
  );
};
