import React from "react";
import { Row, Col, Image, Button, Card } from "antd";
import { useTranslate, useCustom, useDelete } from "@refinedev/core";
import { useSelector, useDispatch } from "react-redux";
import routerProvider from "@refinedev/react-router-v6/legacy";
import "./style.css";

const { Meta } = Card;

export const ProductsByCategory = () => {
  const { categories } = useSelector((state: any) => ({
    ...state,
  }));
  const history = routerProvider.useHistory();
  const dispatch = useDispatch();
  const { data, refetch }: any = useCustom({
    url: `https://medbora.de/api/products/by-category/${categories.selectedCategory._id}`,
    method: "get",
    config: {
      headers: {
        "Content-Type": "image/jpeg",
      },
    },
  });
  return (
    <div>
      <Row gutter={16} justify={"center"}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="header">
            <Row gutter={16} justify={"center"}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div style={{ marginLeft: 50, marginTop: 50, color: "white" }}>
                  <h1
                    className="header__title"
                    style={{ fontWeight: 900, fontSize: "35px" }}
                  >
                    Explore our range and discover why BORA GbR is synonymous
                    with excellence in dental implants.
                  </h1>
                  <h3 className="header__title__content">
                  Our product line offers advanced solutions that cater to the
                    diverse needs of modern implantology. From high-performance
                    implants to cutting-edge components, every product is
                    meticulously engineered to provide reliable results and
                    long-term success.
                  </h3>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Image
                  src="/images/bora/prod2.png"
                  width={"80%"}
                  height={550}
                  style={{ marginTop: -50 }}
                  preview={false}
                  className="header__img"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row
        gutter={[24, 24]}
        justify={"space-around"}
        align={"top"}
        style={{ marginTop: 90 }}
      >
        {data?.data.length > 0 ? (
          data?.data?.map((prod: any) => {
            return (
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                <Card
                  onClick={() => {
                    dispatch({
                      type: "SELECTED_PRODUCT",
                      payload: prod,
                    });
                    history.push(`/products/show/${prod._id}`);
                  }}
                  hoverable
                  className="product__card"
                  style={{
                    // width: "90%",
                    marginBottom: 90,
                    height: 350,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    borderRadius: 12,
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  }}
                  cover={
                    <Image
                      src={`https://medbora.de/uploads/${prod.image}`}
                      preview={false}
                      style={{ objectFit: "cover" }}
                      height={200}
                      className="card-image"
                      width={150}
                    />
                  }
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "translateY(-20px)";
                    e.currentTarget.style.boxShadow =
                      "0 8px 20px rgba(0, 0, 0, 0.15)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "translateY(0)";
                    e.currentTarget.style.boxShadow =
                      "0 4px 12px rgba(0, 0, 0, 0.1)";
                  }}
                >
                  <h3 style={{ fontSize: 12, fontWeight: "bolder" }} >{prod.name} </h3>

                </Card>
              </Col>
            );
          })
        ) : (
          <Row justify={"center"}>
            <Col>
              <h1 style={{ marginBottom: 100, marginTop: 100 }}>
                No Products At This Category
              </h1>
            </Col>
          </Row>
        )}
      </Row>
    </div>
  );
};
